@import "../../style/_settings.scss";

.popup {
  z-index: 90000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  background: $color--background-alt;

  a {
    text-decoration-color: transparentize($color--link, 0.7);
  }

  &:before {
    content: "";
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to bottom,
      $color--background 0%,
      $color--background-alt 100%
    );
  }

  .closeButton {
    position: relative;
    z-index: 10;
    height: 24px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 0;
    display: block;
    background-color: $color--background;
    color: #fff;
    transition: background-color 0.3s;

    > div {
      background-color: inherit;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      position: absolute;
      left: 50%;
      top: -26px;
      transform: translateX(-50%);

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: calc(50% - 2px); //Move up 2px
        left: 50%;
        transform: translateY(-50%) translateX(-50%) rotate(45deg);
        transform-origin: center;
        width: 40%;
        height: 2px;
        background-color: currentColor;
      }

      &:after {
        transform: translateY(-50%) translateX(-50%) rotate(-45deg);
      }
    }

    &:hover {
      background-color: #1e67b1;
    }

    &:active {
      background-color: #3d78b3;
    }
  }

  .content {
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
  }
}
