@import "../../style/_settings.scss";

.hydration {
  display: flex;
  margin: auto;
  justify-content: center;

  margin-top: 0.8em;

  font-size: 1.3em;
  color: $color--text-dim;

  display: flex;
  column-gap: 1em;
  align-items: center;

  > div {
    text-align: center;
  }

  .symbol {
    display: inline-block;
    margin-left: 0.1em;
  }
}
