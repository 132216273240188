*,
::after,
::before {
  box-sizing: border-box;
  line-height: 1;
  backface-visibility: hidden;
}

:root {
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

[hidden] {
  display: none;
}

body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

canvas,
iframe,
img,
select,
svg,
textarea,
video {
  max-width: 100%;
}

button {
  appearance: none;
  display: block;
  margin: 0;
  border: 0;
  padding: 0;
  width: 100%;
  color: inherit;
  background-color: transparent;
  font: inherit;
  text-align: inherit;
  align-items: normal;
}

//a,
button {
  user-select: none;
}

a,
button,
input,
label[for],
textarea {
  cursor: pointer;
}

// img {
//   display: block;
//   height: auto;
// }
