@import "./_settings.scss";

html {
  font-family: "Helvetica Neue", Roboto, "Segoe UI", sans-serif;
  background: $color--background-alt;

  height: 100%;
  width: 100%;

  &:before {
    content: "";
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to bottom,
      $color--background 0%,
      $color--background-alt 100%
    );
  }
}

body {
  color: #fff;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;

  line-height: normal;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  max-width: 812px; // iPhone X
  margin: 0 auto;

  &.has-popup {
    overflow: hidden;
  }
}

#layout {
  min-height: 100vh;

  display: grid;
  //grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  //grid-column-gap: 0;
  //grid-row-gap: 0;
  //align-items: center;
  //margin: 0 0.75em;
  margin: 0 auto;
  padding: 0 0.75em;
  max-width: 500px;
  box-sizing: border-box;

  > footer {
    margin: 1em 0;
    text-align: center;
    font-size: 0.9em;
    opacity: 0.6;
  }
}

::selection {
  color: $color--background-alt;
  background-color: $color--focus;
}

input {
  font-family: inherit;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

a {
  color: $color--link;
  text-decoration: underline;
  transition: color 0.3s, text-decoration-color 0.3s, background-color 0.3s;
  text-decoration-color: transparentize($color--link, 1);

  &:hover {
    color: $color--link;
    text-decoration: underline;
    text-decoration-color: $color--link;
  }
}
