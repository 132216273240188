@import "../../style/_settings.scss";

.textbox {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: $border--size solid $color--border;
  border-radius: $border--radius;
  padding: 0;
  transition: border-color 0.3s;
  height: 2em;

  position: relative;
  display: flex;
  align-items: center;

  background-color: #fff;

  &:has(input:disabled) {
    background-color: transparent;
  }

  &:has(input:focus) {
    outline: 2px solid $color--focus;
  }

  input {
    font-size: 100%;
    text-align: right;
    outline: 0;
    padding: 0;
    color: $color--background-alt;
    background-color: transparent;

    order: 2;
    appearance: textfield;
    border: 0;
    height: 100%;
    margin-top: 0;
    min-width: 0;
    width: 100%;
    max-width: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      // Couldn't get styled consistantly so just remove them
      display: none;
      -webkit-appearance: none;
    }

    &:disabled,
    &::placeholder {
      opacity: 1;
      color: $color--text-dim;
      -webkit-text-fill-color: $color--text-dim;
    }

    &::selection {
      color: $color--background-alt;
      background-color: $color--focus;
    }
  }

  .suffix {
    order: 3;
    position: absolute;
    color: $color--text-dim;
    //line-height: 1;
    padding: 0;
    user-select: none;
    pointer-events: none;
    white-space: nowrap;
    right: 0.5em;

    &[data-suffix="L"] + input {
      //TODO: TEMP
      padding-right: 20px;
    }

    &[data-suffix="%"] + input {
      padding-right: 1.5em;
    }

    &[data-suffix="g"] + input {
      padding-right: 1.2em;
    }

    &[data-suffix="oz"] + input {
      padding-right: 1.7em;
    }
  }
}
