@import "../../style/_settings.scss";

.ingredient {
  position: relative;
  display: grid;
  grid-template-columns: 5fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0.5em;
  //grid-row-gap: 0px;
  align-items: center;
  font-size: 1.5em;

  > label {
    position: absolute;
    z-index: 1;
    left: 0.5em;
    color: $color--text-dim;
  }
}
