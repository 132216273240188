@import "../../style/_settings.scss";

.toggleList {
  display: flex;
  align-items: stretch;

  flex-grow: 1;
  margin: 0 ($border--size / 2);

  > label {
    position: relative;
    background-color: transparent;
    color: $color--text-dim;
    text-align: center;
    padding: 0.75em 0.75em;
    border: $border--size solid $color--border;
    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;

    margin: 0 ($border--size / 2) * -1;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    flex-grow: 1;
    flex-basis: 50%;

    &:hover {
      cursor: pointer;
    }

    &:first-of-type,
    &:first-of-type input {
      border-top-left-radius: $border--radius;
      border-bottom-left-radius: $border--radius;
    }

    &:last-of-type,
    &:last-of-type input {
      border-top-right-radius: $border--radius;
      border-bottom-right-radius: $border--radius;
    }

    &:has(input:checked) {
      background-color: $color--background-alt;
      color: $color--text;
      z-index: 2;
    }

    span {
      position: relative;
      z-index: 3;
    }

    input {
      appearance: none;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      border: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
      background-color: inherit;

      &:focus {
        box-shadow: 0 0 0 $border--size $color--border,
          0 0 0 $border--size * 2 $color--focus;
      }
    }
  }
}
