@import "../../style/_settings.scss";

.ingredients {
  display: grid;
  //grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 1fr);
  //grid-column-gap: 0px;
  grid-row-gap: 0.75em;
  margin: auto;
}
